/* eslint-disable prefer-destructuring */
/* eslint-disable prefer-rest-params */
import React from 'react';
import { Helmet } from 'react-helmet';
import { isSSR } from '../../../../utils/windowUtils';

function Hotjar() {
  if (isSSR()) {
    return null;
  }

  if (process.env.GATSBY_HOTJAR_ID) {
    return (
      <Helmet>
        <script>
          {(function (h, o, t, j, a, r) {
            h.hj =
              h.hj ||
              function () {
                (h.hj.q = h.hj.q || []).push(arguments);
              };
            h._hjSettings = { hjid: process.env.GATSBY_HOTJAR_ID, hjsv: 6 };
            a = o.getElementsByTagName('head')[0];
            r = o.createElement('script');
            r.async = 1;
            r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
            a.appendChild(r);
          })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=')}
        </script>
      </Helmet>
    );
  }

  return null;
}

Hotjar.propTypes = {};

export default Hotjar;
